<template>
  <div class="container-fluid">
    <div class="row bg-light">
      <div class="col text-center col-12">
        <nav class="navbar navbar-light p-3">
          <span class="navbar-brand mb-0 h1">Diary Viewer</span>
          <div class="ml-auto" v-if="user">
            <div class="dropdown">
              <a class="btn dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                <img :src="user.photoURL" class="rounded-circle" alt="User Photo" style="width: 30px; height: 30px;"> {{ user.displayName }}
              </a>

              <ul class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuLink">
                <li><a class="dropdown-item" @click="logout">Sign out</a></li>
              </ul>
            </div>
          </div>
          <div v-else>
            <button @click="login" class="btn btn-primary">Sign in with Google</button>
          </div>
        </nav>
      </div>
    </div>
    <div class="row">
      <div class="col-md-2 bg-light pt-2" id="thumbnail-section">
        <p v-if="error" class="text-danger">{{ error }}</p>
        <div v-if="loading" class="spinner-border" role="status">
          <span class="sr-only">Loading...</span>
        </div>
        <div v-for="page in pages" :key="page.uid" @click="selectPage(page)" class="thumbnail mb-2">
          <img :src="page.url" :class="'img-thumbnail ' + (selectedPage == page ? 'border-primary' : '')" :alt="'Thumbnail of page ' + page.page_number"><br />
          {{ page.page_number }}
          <span v-if="page.completed" class="text-success">&#10004;</span>
        </div>
      </div>
      <div class="col-md-5" id="text-section">
        <div v-if="selectedPage">
          <h3>Page {{ selectedPage.page_number }}</h3>
          <hr />
          <ol v-if="selectedPage.parsed_text">
            <li v-for="(parsed_item, parsed_idx) in selectedPage.parsed_text" v-bind:key="parsed_idx">
              {{ parsed_item }}
            </li>
          </ol>
          <hr />
          <div class="form-check form-switch">
            <input class="form-check-input" type="checkbox" id="completedToggle" v-model="selectedPage.completed" @change="updatePageCompletion(selectedPage)">
            <label class="form-check-label" for="completedToggle">Completed</label>
          </div>
        </div>
        <div v-else>
          <p>Select a page to view its content.</p>
        </div>
      </div>
      <div class="col-md-5" id="image-section">
        <div v-if="selectedPage">
          <img :src="selectedPage.url" class="img-fluid" :alt="'Image of page ' + selectedPage.page_number" @click="showModal(selectedPage.url)">
        </div>
        <div v-else>
          <p>Select a page to view its image.</p>
        </div>
      </div>
    </div>

    <!-- Modal -->
    <div class="modal fade" id="imageModal" tabindex="-1" aria-labelledby="imageModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="imageModalLabel">Full Size Image</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <img :src="modalImageUrl" class="img-fluid" alt="Full Size Image">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {Modal} from 'bootstrap';
import {
  GoogleAuthProvider,
  signInWithPopup,
  setPersistence,
  browserLocalPersistence,
  onAuthStateChanged,
  signOut,
} from "firebase/auth";
import { auth, db } from "./firebaseConfig.js";
import { collection, getDocs, doc, updateDoc } from "firebase/firestore";

export default {
  name: 'App',
  data() {
    return {
      email: '',
      password: '',
      error: null,
      loading: false,
      user: null,
      pages: [],
      selectedPage: null,
      modalImageUrl: '',
    };
  },
  methods: {
    async login() {
      console.debug("Login !");
      const provider = new GoogleAuthProvider();
      try {
        const result = await signInWithPopup(auth, provider);
        this.user = result.user;
        console.debug(`User: ${this.user}`);
        this.fetchPages();
      } catch (e) {
        console.error(e);
        this.error = "Failed to log in. Please try again.";
      }
    },
    async logout() {
      try {
        await signOut(auth);
        this.user = null;
        console.debug("User logged out");
      } catch (e) {
        console.error(e);
        this.error = "Failed to log out. Please try again.";
      }
    },
    async fetchPages() {
      this.loading = true;
      try {
        const querySnapshot = await getDocs(collection(db, "highlighter"));
        this.pages = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        })).sort((a, b) => {
          return a.page_number - b.page_number;
        });
      } catch (e) {
        console.error("Error fetching pages: ", e);
        this.error = "Failed to fetch pages.";
      } finally {
        this.loading = false;
      }
    },
    async updatePageCompletion(page) {
      try {
        const pageRef = doc(db, "highlighter", page.id);
        await updateDoc(pageRef, {
          completed: page.completed
        });
        console.debug(`Page ${page.page_number} completion status updated to ${page.completed}`);
      } catch (e) {
        console.error("Error updating page completion: ", e);
        this.error = "Failed to update page completion.";
      }
    },
    selectPage(page) {
      this.selectedPage = page;
    },
    showModal(imageUrl) {
      this.modalImageUrl = imageUrl;
      const modal = new Modal(document.getElementById('imageModal'));
      modal.show();
    },
  },
  mounted() {
    setPersistence(auth, browserLocalPersistence)
      .catch((e) => console.error(e));

    onAuthStateChanged(auth, (user) => {
      if (user) {
        this.user = user;
        this.fetchPages();
      } else {
        console.debug("Not logged in");
      }
    });
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#thumbnail-section {
  overflow-y: scroll;
  height: 90vh;
}

.thumbnail {
  cursor: pointer;
}

.thumbnail img {
  width: 50%;
  height: auto;
}

#text-section {
  padding: 20px;
  border-right: 1px solid #ccc;
}

#image-section {
  padding: 20px;
}

.spinner-border {
  margin: 20px auto;
  display: block;
}

.text-success {
  font-size: 1.5em;
  vertical-align: middle;
}
</style>
