// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyBiH7S4pfzNii1Bqo6h7JFAoF-8OuCOZTc",
    authDomain: "maokai-e10a4.firebaseapp.com",
    projectId: "maokai-e10a4",
    storageBucket: "maokai-e10a4.appspot.com",
    messagingSenderId: "480169437366",
    appId: "1:480169437366:web:5c8a80e42c653cf37a5e17",
    measurementId: "G-1C2JVJY5E2"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app)
const db = getFirestore(app)

export { app, analytics, db, auth };